.notificationsContainer {
    width: 600px;
    height: calc(100vh - 110px);
    position: absolute;
    right: 170px;
    top: 70px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
    padding: 15px 0;

}

.notificationsSection{
    overflow-y: auto;
    max-height: calc(100vh - 205px);
}

.padding-horizontal-15{
    padding: 0 15px;
}

.flex-spaceBetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-16px-600{
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1d;
}

.horizontalLine{
    background: #D4D4D4;
    height: 1px;
    margin: 0 15px;
} 

.marginBottom-20{
margin-bottom: 20px;
}

.urlButton{
    color: #0055AF;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    position: relative;
}

.position-right{
    justify-content: end;
}

.padding-vertical-10{
    padding-top: 10px;
    padding-bottom: 10px;
}

.notificationContainer{
    padding: 20px 15px 15px 10px;
    cursor: pointer;
}

.notificationHeader{
    display: flex;
    align-items: center;
    gap: 10px;
}

.circle-10{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.notificationTextIcon{
    border-radius: 4px;
width: 30px;
height: 30px;
font-size: 14px;
font-weight: 600;
display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
}

.notificationTime{
    color: #758490;
    font-size: 12px;
    font-style: italic;
    text-align: left;
}

.notificationMessage{
    margin-top: 10px;
    text-align: left;
    margin-left: 20px;
}

.notificationSortByPopover .MuiPaper-root{
    padding: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    overflow: unset;
}

.sortByOptionsContainer {
    box-shadow: 0px 0px 6px #00000029;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    padding: 15px 10px;
    background-color: #FFFFFF;
    text-align: left;
    white-space: nowrap;
    position: absolute;
    top: 35px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 3;
}

.url-text{
    color: #0055af;
}

.noNotifications{
    color: #1d1d1d;
    display: flex;
    height: calc(100vh - 205px);
    align-items: center;
    justify-content: center;
}