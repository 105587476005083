.wrapper {
	display: flex;
	flex-direction: row;
}

.layout {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 88%;
	padding-bottom: 20px;
}

.main-content {
	background: #fff;
	flex: 1;
	position: relative;
	top: 107px;
}
