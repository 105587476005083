/* Hide scrollbar */
.drawer .sidebarToggleIcon.left {
	cursor: pointer;
	display: flex;
	position: fixed;
	left: 322px;
	transition: left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	border: 2px solid #ffffff;
	bottom: 15%;
	cursor: pointer;
	display: flex;
	z-index: 999;
	position: fixed;
	transform: translateX(-50%);
	background: #0055af;
	border-radius: 50%;
	.MuiSvgIcon-root {
		width: 22px !important;
		height: 22px !important;
	}
}

.drawer .sidebarToggleIcon.right {
	cursor: pointer;
	display: flex;
	position: fixed;
	left: 72px;
	transition: left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	border: 2px solid #ffffff;
	bottom: 15%;
	cursor: pointer;
	display: flex;
	z-index: 999;
	position: fixed;
	transform: translateX(-50%);
	background: #0055af;
	border-radius: 50%;
	.MuiSvgIcon-root {
		width: 22px !important;
		height: 22px !important;
	}
}

.drawer .Mui-selected {
	background-color: #0055af !important;
}

.drawer::-webkit-scrollbar,
.MuiDrawer-paper::-webkit-scrollbar {
	display: none;
}

.drawer,
.MuiDrawer-paper {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	background-color: #033162 !important;
}

.navbar-drawer .MuiDrawer-paper {
	display: flex;
	align-items: center;
	height: 100vh;
	padding: 0;
}

.drawerOpen {
	width: 322px;
	overflow-x: hidden;
	transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.drawerClose {
	width: 72px;
	overflow-x: hidden;
	transition: width 125ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.drawer .logo {
	overflow: hidden;
	height: 72px;
}

.logo.drawerOpen {
	text-align: left;
}

.logoImage{
	margin: 21px;
}

.drawer .sidebarMenu {
	width: 100%;
	height: 54px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.drawer .sidebarMenu.active {
	svg {
		fill: #fff;
	}
	background-color: #0055af;
}

.drawer .listitemSidebar {
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		color: white;
	}
}

.drawer .MuiSvgIcon-root {
	width: 18px !important;
	height: 18px !important;
	fill: #fff;
}

.drawer .MuiListItemIcon-root {
	min-width: unset;
}
.drawer .menuItem {
	padding: 0;
}

.drawer .menuItem.no-click {
	// pointer-events: none;
	// cursor: default;
}

.drawer .sidebar-list-label {
	text-align: left;
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #ffffff;
}

.drawerClose .sidebar-list-label {
	display: none;
}

.drawer .sidebarFooter {
	position: absolute;
	bottom: 24px;
	color: white;
	padding: 0 0 20px 28px;
}

.drawer .sidebarFooter svg {
	cursor: pointer;
}

.drawer .sidebarFooter > a {
	color: #fff;
}

.sidebarMiddle {
	width: 100%;
	.MuiList-root{
		padding: 0;
	}
	.MuiButtonBase-root.Mui-selected {
		background-color: unset !important;
	}
	.MuiButtonBase-root.Mui-selected .sidebarMenu {
		svg {
			fill: #fff;
		}
		background-color: #0055af;
	}
	.sidebar-submenu .sidebarMenu {
		background-color: unset !important;
	}
	.sidebar-submenu .sidebarMenu.active .sidebar-list-label {
		background-color: unset !important;
		color: #05accb;
	}
}

.drawer.drawerOpen .sidebarMenu {
	padding: 0 24px;
	align-items: baseline;
}

.drawer.drawerOpen .listitemSidebar {
	gap: 40px;
}

.drawer .sidebar-submenu {
	display: none;
}
.drawer.drawerOpen .sidebar-submenu {
	display: block;
}
