.ia-button {
	display: flex;
	gap: 10px;
	align-items: center;
}

.ia-button .icon {
	height: 24px;
	color: rgb(0, 85, 175);
	padding-right: 10px;
}

.ia-button .icon.non-material {
	display: flex;
	justify-content: center;
	align-items: center;
}

.colorWhite {
	color: #ffffff;
}
